import middleware from './middleware'

/**
 * @see https://nuxt.com/docs/guide/directory-structure/plugins
 */
export default defineNuxtPlugin({
  name: 'attain',
  dependsOn: [],

  async setup() {
    /**
     * @see https://nuxt.com/docs/api/utils/add-route-middleware
     */
    addRouteMiddleware('auth', middleware, { global: true })

    const Auth = useAuth()
    const Chat = useChat()
    const Partner = usePartner()

    Auth.addEventListener('login', () => {
      useTimeout(Chat.refreshGroups, 1000)
      Chat.startPolling()
      Partner.loadPartner()
    })

    Auth.addEventListener('logout', () => {
      Chat.clear()
      Partner.clear()
    })

    try {
      // Restore access token from cookies and fetch user as needed.
      await Auth.restoreFromCookie()
      if (Auth.state.isLoggedIn) {
        useTimeout(Chat.refreshGroups, 3000)
      }
    }
    catch (err) {
      // Ignore any error to avoid app start crash
      console.error(err)
    }
  },
})
