import type { ChatGroupResponse } from '../chat-module'
import { type Customer, CustomerStatus } from './Customer'
import type { CustomerBuyingPower, LoanProgram } from './CustomerBuyingPower'

export * from './Customer'
export * from './CustomerBuyingPower'
export * from './EquifaxCreditReportTrade'
export * from './EquifaxModel'
export * from './EquifaxNarrativeCode'

const baseURL = '/partner/customers'

export function transformCustomerBuyingPowerResponse(data: CustomerBuyingPower): CustomerBuyingPower {
  data.settings.targetDate = new Date(data.settings.targetDate)
  return data
}

export function useCustomerService() {
  return {
    fetchCustomers(query?: { accountId: string } | { customerId: string }) {
      return useAttainApi<Customer[]>(`${baseURL}/`, { method: 'GET', query })
    },
    fetchCustomer(customerId: string) {
      return useAttainApi<Customer[]>(`${baseURL}/${customerId}`, { method: 'GET' })
    },
    createCustomer(customer: Pick<Customer, 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'locale'>) {
      return useAttainApi<Customer>(`${baseURL}/`, { method: 'POST', body: customer })
    },
    updateCustomer(customer: Pick<Customer, '_id' | 'status'>) {
      const { _id, ...body } = customer
      return useAttainApi<Customer>(`${baseURL}/${_id}`, { method: 'PUT', body })
    },
    fetchBuyingPowerByCustomerId(id: string) {
      return useAttainApi<CustomerBuyingPower>(`${baseURL}/${id}/buying-power`)
        .then(transformCustomerBuyingPowerResponse)
        .catch((err) => {
          if (isFetchError(err) && err.statusCode === 404) return undefined
          throw err
        })
    },
    postChatGroupForCustomer(customerId: string) {
      return useAttainApi<ChatGroupResponse>(`${baseURL}/${customerId}/chat`, { method: 'POST' })
    },
    translateStatus(status: CustomerStatus) {
      switch (status) {
        case CustomerStatus.CREATED:
          return 'Created'
        case CustomerStatus.INVITED:
          return 'Invited'
        case CustomerStatus.ACTIVE:
          return 'Active'
        case CustomerStatus.PENDING_AGREEMENT:
          return 'Pending agreement'
        case CustomerStatus.AGREEMENT_SIGNED:
          return 'Signed'
        default:
          return status
      }
    },
    translateHomeType(homeType: HomeType) {
      switch (homeType) {
        case 'HOUSE':
          return 'House'
        case 'CONDO':
          return 'Condo'
        case 'ANY':
          return 'House or condo'
        default:
          return homeType
      }
    },
    translatePortfolioTypeCode(portfolioTypeCode: EquifaxCreditReportTrade['portfolioTypeCode']): string {
      switch (portfolioTypeCode.code) {
        case 'C': return 'Line of Credit'
        case 'I': return 'Installment'
        case 'M': return 'Mortgage'
        case 'O': return 'Open Account'
        case 'R': return 'Revolving'
        default: return portfolioTypeCode.description
      }
    },
    translateLoanProgram(loanProgram: LoanProgram): string {
      switch (loanProgram) {
        case 'Fixed30Year': return '30-year fixed-rate'
        default: return loanProgram
      }
    },
  }
}
