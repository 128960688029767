import type { PlaceDetails } from '../../googleapis'

export enum CustomerStatus {
  CREATED = 'CREATED',
  INVITED = 'INVITED',
  ACTIVE = 'ACTIVE',
  PENDING_AGREEMENT = 'PENDING_AGREEMENT',
  AGREEMENT_SIGNED = 'AGREEMENT_SIGNED',
}

export interface Customer {
  _id: string
  partnerAccountId: string
  accountId: string
  status: CustomerStatus
  firstName: string
  lastName: string
  email?: string
  phoneNumber?: string
  locale: string
  city: string
  state: string
  location: string
  locationMetadata: PlaceDetails
  chatGroupId?: string
  createdAt: string
  readinessScore?: number
}
