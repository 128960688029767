import type { ChatGroupMemberResponse } from './chat'

const baseURL = '/chat'

export type QueryMessageDirection = 'older' | 'newer'

export async function fetchChatGroups() {
  return useAttainApi<ChatGroupResponse[]>(`${baseURL}/groups`)
}

export async function fetchChatGroupMessages(params: {
  groupId: string
  messageId?: string
  direction?: QueryMessageDirection
  limit?: number
}) {
  const { groupId, ...query } = params
  return useAttainApi<ChatMessageResponse[]>(`${baseURL}/groups/${groupId}/messages`, { method: 'GET', query })
}

export async function postChatMessage(data: Pick<ChatMessageResponse, 'groupId' | 'message'>) {
  const { groupId, message } = data
  return useAttainApi<ChatMessageResponse>(`${baseURL}/groups/${groupId}/messages`, { method: 'POST', body: { message } })
}

export async function markChatGroupAsRead(groupId: string) {
  return useAttainApi<ChatGroupMemberResponse>(`${baseURL}/groups/${groupId}/mark-as-read`, { method: 'POST' })
}
