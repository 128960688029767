import type { Partner } from './Partner'
import { usePartnerStore } from './store'

export * from './store'

const baseURL = '/partner'

export type PartnerResponse = Partner & { createdAt: string }

function transformPartnerResponse(partner: PartnerResponse): Partner {
  return {
    ...partner,
    createdAt: new Date(partner.createdAt),
  }
}

function fetchPartner() {
  return useAttainApi<PartnerResponse>(`${baseURL}/me`).then(transformPartnerResponse)
}

export function usePartner() {
  const store = usePartnerStore()

  async function loadPartner() {
    const partner = await fetchPartner()
    store.setPartner(partner)
    return partner
  }

  function clear() {
    store.clear()
  }

  function buildInviteLink(options?: { customerId?: string }) {
    const { user } = useAuth()
    const { customerBaseUrl, inviteLinkURL, customerInviteLinkURL } = useAttainConfig()
    const referralCode = user.value?.referralCode ?? ''
    if (!referralCode) return ''
    if (options?.customerId) return customerBaseUrl + customerInviteLinkURL.replace(':referralCode', referralCode).replace('{customerId}', options.customerId)
    return customerBaseUrl + inviteLinkURL.replace(':referralCode', referralCode)
  }

  return { loadPartner, fetchPartner, buildInviteLink, clear }
}
