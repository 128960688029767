import { fetchChatGroupMessages, fetchChatGroups } from './api'
import type { ChatMessage } from './chat'

export * from './api'
export * from './chat'
export * from './store'
export * from './utils'

let $polling = null as NodeJS.Timeout | null

const state = reactive({
  polling: false,
})

export function useChat() {
  const { isLoggedIn } = useAuth()
  const store = useChatStore()

  function startPolling(timeout = 60000, options?: Parameters<typeof refreshGroups>[0]) {
    stopPolling()
    if (isLoggedIn.value && !$polling) {
      state.polling = true
      $polling = setInterval(async () => {
        await refreshGroups(options)
      }, timeout)
    }
  }

  function stopPolling() {
    if ($polling) {
      clearInterval($polling)
      $polling = null
      state.polling = false
    }
  }

  async function refreshGroups(options: { pullMessages: false }): Promise<undefined>
  async function refreshGroups(options?: { pullMessages?: boolean }): Promise<ChatMessageResponse[]>
  async function refreshGroups(options?: { pullMessages?: boolean }) {
    const groups = await fetchChatGroups()
    store.setGroups(groups)
    if (options?.pullMessages !== false) {
      const groupMessages = await Promise.all(groups.map(group => pullGroupMessages(group._id)))
      if (!store.state.isInitialized) store.setIsInitialized(true)
      return groupMessages.flat()
    }
  }

  async function pullGroupMessages(groupId: string) {
    const lastMessage = store.groups.value.find(group => group._id === groupId)?.messages?.at(-1)
    const messages = await fetchChatGroupMessages({ groupId, direction: 'newer', messageId: lastMessage?._id })
    store.addMessages(messages)
    return messages
  }

  async function loadPreviousGroupMessages(groupId: string) {
    const firstMessage = store.groups.value.find(group => group._id === groupId)?.messages?.at(0)
    const messages = await fetchChatGroupMessages({ groupId, direction: 'older', messageId: firstMessage?._id })
    store.addMessages(messages)
    return messages
  }

  async function sendGroupMessage(message: Pick<ChatMessage, 'groupId' | 'message'>) {
    await postChatMessage(message)
    await refreshGroups()
  }

  async function markGroupAsRead(groupId: string) {
    await markChatGroupAsRead(groupId)
  }

  function findActiveGroupWithMemberAccountId(accountId: string) {
    return store.groups.value.find(group => group.members.findIndex(member => member.accountId === accountId) >= 0)
  }

  /**
   * Stop active polling and clear state
   */
  function clear() {
    stopPolling()
    store.clear()
  }

  return {
    state,
    refreshGroups,
    pullGroupMessages,
    loadPreviousGroupMessages,
    sendGroupMessage,
    markGroupAsRead,
    findActiveGroupWithMemberAccountId,
    startPolling,
    stopPolling,
    clear,
  }
}
