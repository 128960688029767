import type { Partner } from './Partner'

const state = reactive({
  partner: null as Partner | null,
})

export function usePartnerStore() {
  return {
    state: readonly(state),
    setPartner(partner: Partner) {
      state.partner = partner
    },
    clear() {
      state.partner = null
    },
  }
}
