import type { ChatGroup, ChatGroupMember, ChatGroupMemberResponse, ChatMessage } from './chat'

const { user } = useAuth()

const state = reactive({
  isInitialized: false,
  groups: [] as ChatGroupResponse[],
  messages: [] as ChatMessageResponse[],
})

const groups = computed<ChatGroup[]>(() => {
  const accountId = user.value?._id
  const groups = state.groups?.map((group) => {
    const members = group.members.map(member => transformChatGroupMemberResponse(member, accountId))
    const messages = state.messages
      .filter(message => message.groupId === group._id)
      .map(message => transformChatMessageResponse(message, accountId))
      .sort((a, b) => a.createdAt.getTime() > b.createdAt.getTime() ? 1 : 0)
    const { me, others } = splitMembers(members)
    return {
      _id: group._id,
      members,
      me,
      otherMembers: others,
      createdBy: group.createdBy,
      createdAt: new Date(group.createdAt),
      messages,
      lastSeenAt: me?.lastSeenAt ? new Date(me.lastSeenAt) : undefined,
      lastMessageId: me?.lastMessageId,
      lastMessage: me?.lastMessage,
      unreadMessages: me?.unreadMessages ?? 0,
    }
  }) ?? []
  return groups.sort((a, b) => {
    if (a.unreadMessages > b.unreadMessages) return -1
    if (a.unreadMessages < b.unreadMessages) return 1
    const lastMessageDateA = a.lastMessage?.createdAt ?? 0
    const lastMessageDateB = b.lastMessage?.createdAt ?? 0
    if (lastMessageDateA > lastMessageDateB) return -1
    if (lastMessageDateA < lastMessageDateB) return 1
    return a.createdAt.getTime() > b.createdAt.getTime() ? 0 : 1
  })
})

function splitMembers(members: ChatGroupMember[]) {
  const accountId = user.value?._id
  let me = null
  const others: ChatGroupMember[] = []
  for (const member of members) {
    if (member.accountId === accountId) me = member
    else others.push(member)
  }
  return { me, others }
}

function findMyGroupMember(members: ChatGroupMemberResponse[]) {
  if (!user.value) return undefined
  const accountId = user.value._id
  return members.find(member => member.accountId === accountId)
}

const unreadMessages = computed(() => {
  return state.groups.reduce((acc, group) => {
    const unreadMessages = findMyGroupMember(group.members)?.unreadMessages ?? 0
    return acc + unreadMessages
  }, 0)
})

function transformChatGroupMemberResponse(member: ChatGroupMemberResponse, ownerAccountId: string | undefined): ChatGroupMember {
  return {
    _id: member._id,
    memberId: member.memberId,
    accountId: member.accountId,
    groupId: member.groupId,
    firstName: member.firstName,
    lastName: member.lastName,
    photo: member.photo,
    // photo: `https://picsum.photos/1024/768?random=${member._id}`,
    lastSeenAt: member.lastSeenAt ? new Date(member.lastSeenAt) : undefined,
    lastMessageId: member.lastMessageId,
    lastMessage: member.lastMessage ? transformChatMessageResponse(member.lastMessage, ownerAccountId) : undefined,
    unreadMessages: member.unreadMessages ?? 0,
    role: member.role,
    createdAt: new Date(member.createdAt),
  }
}

function transformChatMessageResponse(message: ChatMessageResponse, ownerAccountId: string | undefined): ChatMessage {
  return {
    _id: message._id,
    groupId: message.groupId,
    sender: message.sender,
    message: message.message,
    owner: message.sender?.accountId === ownerAccountId,
    createdAt: new Date(message.createdAt),
  }
}

export function useChatStore() {
  return {
    state: readonly(state),
    groups,
    unreadMessages,
    setGroups(groups: ChatGroupResponse[]) {
      state.groups = groups
    },
    setMessages(messages: ChatMessageResponse[]) {
      state.messages = messages
    },
    addMessages(messages: ChatMessageResponse[]) {
      state.messages = [...state.messages, ...messages]
    },
    setIsInitialized(isInitialized: boolean) {
      state.isInitialized = isInitialized
    },
    clear() {
      state.isInitialized = false
      state.groups = []
      state.messages = []
    },
  }
}
